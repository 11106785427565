"use client";

import {
  createContext,
  useContext,
  useEffect,
  useState,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
} from "react";

type ConfirmContext = {
  isAsking: boolean;
  setIsAsking: Dispatch<SetStateAction<boolean>>;
  message?: ReactNode;
  setMessage: Dispatch<SetStateAction<ConfirmContext["message"]>>;
  title?: ReactNode;
  setTitle: Dispatch<SetStateAction<ConfirmContext["title"]>>;
  resolve?: ((value: boolean) => void) | undefined;
  setResolve: Dispatch<SetStateAction<ConfirmContext["resolve"]>>;
};

const confirmContext = createContext<ConfirmContext | null>(null);

export function useConfirm() {
  const context = useContext(confirmContext);
  if (!context) throw Error("'useConfirm' use outside of Provider detected");
  const {
    message,
    setMessage,
    title,
    setTitle,
    resolve,
    setResolve,
    setIsAsking,
    isAsking,
  } = context;

  const confirm = async (
    message: ReactNode,
    {
      title,
    }: {
      title?: ReactNode;
    } = {}
  ): Promise<boolean> => {
    return new Promise((resolve) => {
      setMessage(message);
      setTitle(title);
      setIsAsking(true);
      setResolve(() => (value: boolean) => resolve(value));
    });
  };

  const onConfirm = () => {
    resolve?.(true);
    setIsAsking(false);
  };
  const onDeny = () => {
    resolve?.(false);
    setIsAsking(false);
  };

  useEffect(() => {
    if (!isAsking) {
      // timeout to prevent the message from disappearing whilst modal is still up
      setTimeout(() => setMessage(undefined), 300);
    }
  }, [isAsking]);

  return { message, title, isAsking, confirm, onConfirm, onDeny };
}

export const UseConfirmProvider = ({ children }: { children: ReactNode }) => {
  const [isAsking, setIsAsking] = useState<ConfirmContext["isAsking"]>(false);
  const [message, setMessage] = useState<ConfirmContext["message"]>();
  const [title, setTitle] = useState<ConfirmContext["title"]>();
  const [resolve, setResolve] = useState<ConfirmContext["resolve"]>();

  return (
    <confirmContext.Provider
      value={{
        isAsking,
        setIsAsking,
        message,
        setMessage,
        title,
        setTitle,
        resolve,
        setResolve,
      }}
    >
      {children}
    </confirmContext.Provider>
  );
};
